.searchBorder {
  width: 100%;
  position: absolute;
  z-index: 500;
  border: solid 2px transparent;
  border-radius: 8px;
  padding: 1rem 0.5rem;
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem;
  background: linear-gradient(white, white), black;
  background-origin: border-box;
  background-clip: padding-box, border-box;
  box-sizing: border-box;
  overflow-y: scroll;
  max-height: 400px;
  cursor: pointer;
}
